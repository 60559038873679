import { getApiHeaders, handleError } from "../utils/utils";
import API from "./api";
import {
  ACTIVATE_ACCOUNT,
  CHANGE_PASSWORD,
  CREATE_ACCOUNT,
  EMAIL_RESTORE_PASSWORD,
  GET_PAYMENT_HISTORY,
  LOGIN,
  PASSWORD_RECOVERY,
  RECENT_ACCOUNTS,
  RESTORE_PASSWORD,
} from "./endpoints";
import { CreateAccountException } from "./exceptions/create-account.exception";

/**
 * Obtiene los pagos asociados a un usuario pagador
 */
export const requestGetPaymentHistory = async (params?: {
  rut?: string;
  document?: string;
  [key: string]: string | undefined;
}) => {
  try {
    const headers = getApiHeaders();
    if (params) {
      for (const key of Object.keys(params)) {
        if (!params[key]) {
          delete params[key];
        }
      }
    }
    return await API.get(GET_PAYMENT_HISTORY, {
      headers,
      params,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Utilizado para crear una cuenta de usuario
 */
export const requestCreateAccount = async (
  rut: string,
  name: string,
  lastName: string,
  phone: string,
  email: string,
  password: string
) => {
  try {
    const response = await API.post(CREATE_ACCOUNT, {
      rut,
      name,
      lastName,
      phone,
      email,
      password,
    });
    if (response.status !== 200 && response.status !== 201) {
      throw response;
    }
    return response;
  } catch (error: any) {
    handleError(error);
    throw new CreateAccountException(error?.response?.data?.message);
  }
};

/**
 * Utilizado para activar una cuenta de usuario
 */
export const requestActivateAccount = async (token: string) => {
  try {
    return await API.post(ACTIVATE_ACCOUNT(token));
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestLogin = async (email: string, password: string) => {
  try {
    let response: any = await API.post(LOGIN, {
      email,
      password,
    });

    if (response?.response) {
      response = response?.response;
    }

    if (response.status !== 200 && response.status !== 201) {
      throw response;
    }

    return response;
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Solicita un cambio de contraseña para un usuario logeado
 */
export const requestChangePassword = async (
  currentPassword: string,
  newPassword: string
) => {
  try {
    const headers = getApiHeaders();
    return await API.patch(
      CHANGE_PASSWORD,
      {
        currentPassword,
        newPassword,
      },
      { headers }
    );
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Obtiene listado con los ruts pagados anteriormente por el usuario autenticado
 */
export const requestRecentAccounts = async () => {
  try {
    const headers = getApiHeaders();
    return await API.get(RECENT_ACCOUNTS, { headers });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestPasswordRecovery = async (email: string) => {
  try {
    return await API.post(PASSWORD_RECOVERY, {
      email,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestRestorePassword = async (
  token: string,
  password: string
) => {
  try {
    return await API.post(RESTORE_PASSWORD, {
      token,
      password,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestEmailRestorePassword = async (token: string) => {
  try {
    return await API.post(EMAIL_RESTORE_PASSWORD, {
      token,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};
