import numeral from "numeral";
import { Col, Row } from "reactstrap";
import { PaymentResult } from "..";
import { ReactComponent as CheckCircleIcon } from "../../../../assets/svg/icons/check-circle.svg";
import CustomButton from "../../../../components/button/CustomButton";
import { t } from "../../../../utils/utils";

interface PaymentInfoTableProps {
  paymentInfo: PaymentResult;
}

const PaymentInfoTable = ({ paymentInfo }: PaymentInfoTableProps) => {
  return (
    <table className="table custom-table-info px-l mb-t">
      <tbody>
        <tr>
          <th scope="row">{t("buy_order")}:</th>
          <td>{paymentInfo.buyOrder}</td>
        </tr>
        <tr>
          <th scope="row">{t("order_date")}:</th>
          <td>{paymentInfo.transactionDate}</td>
        </tr>
        <tr>
          <th scope="row">{t("amount")}:</th>
          <td>$ {numeral(paymentInfo.amount).format()}</td>
        </tr>
        <tr>
          <th scope="row">{t("authorization_code")}:</th>
          <td>{paymentInfo.authorizationCode}</td>
        </tr>
        <tr>
          <th scope="row">{t("transaction_date")}:</th>
          <td>{paymentInfo.transactionDate} </td>
        </tr>
        <tr>
          <th scope="row">{t("payment_type")}:</th>
          <td>{paymentInfo.paymentTypeCode}</td>
        </tr>
        <tr>
          <th scope="row">{t("installment_quantity")}:</th>
          <td>{paymentInfo.installmentsNumber}</td>
        </tr>
        <tr>
          <th scope="row">{t("banking_card")}:</th>
          <td>**** **** **** {paymentInfo.cardNumber}</td>
        </tr>
      </tbody>
    </table>
  );
};

const WebpaySuccessMessage = () => {
  return (
    <div className="container-max-400 mx-auto pb-4">
      <Row>
        <Col xs={12} className="text-center p-4">
          <CheckCircleIcon
            className="svg-secondary"
            width="60px"
            height="60px"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center fs-1-5 color-dark">
          {t("payment_success")}
        </Col>
      </Row>
    </div>
  );
};

type WebpaySuccessInfoProps = {
  orderData: PaymentResult | undefined;
  error: string;
  request: () => {};
  isLoading: boolean;
};

export default function WebpaySuccessInfo({
  orderData,
  error,
  request,
  isLoading,
}: WebpaySuccessInfoProps) {
  return (
    <div>
      <WebpaySuccessMessage />
      <div className="mx-auto container-max-400 bg-color-body custom-rounded">
        <Row className="px-3 py-4">
          <Col xs={12} className="payment-info-container">
            <div className="table-responsive">
              {!orderData && !error ? (
                <div
                  className="spinner-border text-secondary spinner-border spinner-border-lg"
                  role="status"
                >
                  <span className="sr-only">{t("loading")}...</span>
                </div>
              ) : null}
              {orderData ? <PaymentInfoTable paymentInfo={orderData} /> : null}
              {error === "unknown error" ? (
                <>
                  <div className="text-center fs-1-15 color-light pb-4 px-4">
                    {t("error_could_not_get_payment_info")}
                  </div>
                  <div className="px-3 pt-3">
                    <CustomButton
                      text={t("retry")}
                      onClick={request}
                      loading={isLoading}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
