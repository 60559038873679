// Hooks
import { Ref, forwardRef, useCallback, useImperativeHandle } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Utils
import { addToast } from "@octano/global-ui";
import { clean } from "rut.js";
import { GatewayOpenOptions } from "..";
import { SoftlandDebtKey } from "../../../../../../types/debtType";
import useUserStore from "../../../../store/user/userStore";
import { payDebtsRequest } from "../../../api";

export type WebpayPlusMethods = {
  handle: (options: GatewayOpenOptions) => void;
};

export type WebpayPlusRetreived = {
  url: string;
  token: string;
  jwt?: string;
  anonymousUserEmail?: string;
  recoveryKey?: string;
};

type WebpayPlusProps = {
  onSetLoading?: (next: boolean) => void;
  onTokenRetreived?: (response: WebpayPlusRetreived) => void;
};

// Render
const WebpayPlus = (
  { onSetLoading, onTokenRetreived }: WebpayPlusProps,
  ref: Ref<WebpayPlusMethods>
) => {
  const debts = useUserStore((state) => state.debts);
  const { t } = useTranslation();

  const handleToken = useCallback(
    (response: WebpayPlusRetreived) =>
      !!onTokenRetreived && onTokenRetreived(response),
    [onTokenRetreived]
  );

  const setLoading = useCallback(
    (next: boolean) => !!onSetLoading && onSetLoading(next),
    [onSetLoading]
  );

  const handlePayment = useCallback(
    async (options?: GatewayOpenOptions) => {
      try {
        setLoading(true);

        const selectedDebts = debts
          .filter((debt) => debt.selected && debt.total)
          .map((debt) => ({
            key: { IDENTIFICADOR_DEUDA: debt.key } as SoftlandDebtKey,
            amount: Number(debt.total),
          }));

        const rut = !options?.isLogged
          ? clean(options?.payerRut || "").slice(0, -1)
          : undefined;

        const { data: response } = await payDebtsRequest(
          {
            debts: selectedDebts,
            rut,
          },
          !!options?.isLogged
        );

        if (!options?.isLogged && response?.orderJwt) {
          handleToken({
            url: response?.initURL,
            token: response?.token,
            jwt: response?.orderJwt,
            anonymousUserEmail: options?.payerEmail,
            recoveryKey: response?.recoveryKey,
          });
        } else {
          handleToken({
            url: response?.initURL,
            token: response?.token,
            recoveryKey: response?.recoveryKey,
          });
        }
      } catch (_error: any) {
        setLoading(false);
        addToast({
          icon: "error",
          color: "danger",
          text: _error?.data?.message?.trim() || t("unexpected_error_msg"),
        });
      }
    },
    [setLoading, t, handleToken, debts]
  );

  useImperativeHandle(ref, () => ({
    handle: handlePayment,
  }));

  return <></>;
};

export default forwardRef(WebpayPlus);
