import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "reactstrap";
import { requestRestorePassword } from "../../api/request";
import CustomAlert from "../../components/alert/CustomAlert";
import AuthSingleCard from "../../components/auth/AuthSingleCard";
import CustomButton from "../../components/button/CustomButton";
import { t } from "../../utils/utils";
import { TextInput } from "@octano/global-ui";

type InputsRestorePassword = {
  password: string;
  repeatPassword: string;
};

type RestorePasswordFormProps = {
  email: string;
  token: string;
  setSuccessRestore: React.Dispatch<React.SetStateAction<boolean>>;
};

const RestorePasswordForm = ({
  setSuccessRestore,
  email,
  token,
}: RestorePasswordFormProps) => {
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    getValues,
    trigger,
    control,
  } = useForm<InputsRestorePassword>({
    mode: "onChange",
  });
  const [errorRestore, setErrorRestore] = useState(false);

  const onSubmit = async (values: InputsRestorePassword) => {
    try {
      await requestRestorePassword(token, values.password);
      setSuccessRestore(true);
    } catch (error) {
      setErrorRestore(true);
    }
  };

  return (
    <AuthSingleCard
      title="Restablecer contraseña"
      message="Ingresa una nueva contraseña para acceder al portal de pagos."
    >
      <Row className="px-1 px-sm-5">
        <Col xs={12} className="text-center pt-2">
          <span className="d-block">{t("email")}</span>
          <span className="fs-1-2 text-primary">{email}</span>
        </Col>
        {errorRestore && (
          <Col xs={12} className="pt-2">
            <CustomAlert type="error" text={t("unexpected_error_msg")} />
          </Col>
        )}
        <Col xs={12} className="pt-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label={t("password")}
              name="password"
              type="password"
              rules={{
                required: t("required_field"),
                minLength: {
                  value: 8,
                  message: t("min_length_password"),
                },
              }}
              control={control}
              onChange={() => {
                if (getValues("repeatPassword") !== "") {
                  trigger("repeatPassword");
                }
              }}
              tooltip={t("min_length_password")}
            />
            <TextInput
              type="password"
              label={t("repeat_password")}
              name="repeatPassword"
              rules={{
                required: t("required_field"),
                validate: (value: string) => {
                  if (value !== getValues("password")) {
                    return t("validate_repeat_password");
                  }
                  return undefined;
                },
                minLength: {
                  value: 8,
                  message: t("min_length_password"),
                },
              }}
              control={control}
            />

            <div className="py-4">
              <CustomButton
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                text={t("confirm")}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </AuthSingleCard>
  );
};

export default RestorePasswordForm;
