import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { clean, validate as validateRut } from "rut.js";
import { RECAPTCHA_SITE_KEY } from "../../config/constants";
import { t } from "../../utils/utils";

import { TextInput } from "@octano/global-ui";
import { FormGroup } from "reactstrap";
import CustomAlert from "../../components/alert/CustomAlert";
import CustomButton from "../../components/button/CustomButton";

const DEFAULT_VALUES = {
  rut: "",
  token: "",
};

type FormValues = typeof DEFAULT_VALUES;

export interface PersonData {
  rut: string;
  name: string;
  lastName: string;
}

interface Props {
  onRutVerificationSuccess?: (person: PersonData) => void;
}

export default function RutSearch({
  onRutVerificationSuccess = () => null,
}: Props) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  const recaptchaRef = useRef<ReCAPTCHA>();
  const [notFound, setNotFound] = React.useState(false);
  const searchRut = async (values: FormValues) => {
    try {
      let person = {
        name: "",
        lastName: "",
        rut: `${values.rut}`,
      };
      onRutVerificationSuccess(person);
    } catch (err) {
      setNotFound(true);
    } finally {
      if (recaptchaRef.current) {
        reset();
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(searchRut)} className="px-3 py-4 mt-n5">
      {notFound && <CustomAlert type="error" text={t(`rut_not_found`)} />}

      <TextInput
        control={control}
        label={t("label_rut")}
        name="rut"
        formatter="rut"
        rules={{
          required: t("rut_required"),
          validate: (value: string) => {
            if (!validateRut(clean(value))) {
              return t("invalid_rut");
            } else {
              return undefined;
            }
          },
        }}
      />

      <FormGroup className="pt-4 g-recaptcha-center">
        <Controller
          defaultValue={""}
          control={control}
          name="token"
          rules={{ required: false }}
          render={({ field }) => {
            return (
              <ReCAPTCHA
                ref={field.ref}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={field.onChange}
                hl={t("language_code")}
                size="normal"
              />
            );
          }}
        />
      </FormGroup>

      <CustomButton
        text={t("next")}
        className="mt-5"
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        type="submit"
      />
    </form>
  );
}
