import { IStepsSystem } from "../../../types/stepsSystem";
import PaymentStep from "../paymentStep";
import SearchStep from "../searchStep";
import VoucherStep from "../voucherStep";

export const useStepsSystemMemo = (): IStepsSystem => {
  return {
    UFRO: {
      steps: [
        {
          name: "search-step",
          component: SearchStep,
        },
        {
          name: "payment-step",
          component: PaymentStep,
        },
        {
          name: "voucher-step",
          component: VoucherStep,
        },
      ],
    },
  };
};
