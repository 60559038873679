import { AuthContainer, Button, TextInput } from "@octano/global-ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { requestLogin } from "../../api/request";
import { ReactComponent as LogoGlobal } from "../../assets/svg/logo_global_color.svg";
import CustomAlert from "../../components/alert/CustomAlert";
import { Layouts } from "../../config/constants";
import { useUserState } from "../../hooks";
import { t } from "../../utils/utils";
import ForgotPasswordModal from "./ForgotPasswordModal";
import RecoveryEmailSentModal from "./RecoveryEmailSentModal";
import useStepStore from "../paymentFlow/store/step/stepStore";

interface Inputs {
  email: string;
  password: string;
}

enum ModalType {
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RECOVERY_EMAIL_SENT = "RECOVERY_EMAIL_SENT",
  NONE = "NONE",
}

const Auth = () => {
  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.NONE);
  const { isLogged, setIsLogged, setProfile } = useUserState();
  useUserState();
  const resetStep = useStepStore((state) => state.resetStep);

  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm();
  const [errorForm, setErrorForm] = useState<string>();

  useEffect(() => {
    resetStep();
  }, [resetStep]);

  const onLogin = async (values: Inputs) => {
    try {
      const { data } = await requestLogin(values.email, values.password);
      if (data) {
        setProfile({
          id: data.id,
          rut: data.rut,
          name: data.name,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        });
        setIsLogged(true);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        setErrorForm(t("invalid_login"));
      } else {
        setErrorForm(t("unexpected_error_msg"));
      }
    }
  };

  const enterWithoutAuthentication = () => {
    history.push(Layouts.DASHBOARD);
  };

  if (isLogged) {
    return <Redirect to={Layouts.USER_DASHBOARD} />;
  }
  return (
    <>
      <ForgotPasswordModal
        isOpen={openedModal === ModalType.FORGOT_PASSWORD}
        onCancel={() => setOpenedModal(ModalType.NONE)}
        onEmailSent={() => setOpenedModal(ModalType.RECOVERY_EMAIL_SENT)}
      />
      <RecoveryEmailSentModal
        isOpen={openedModal === ModalType.RECOVERY_EMAIL_SENT}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
      />
      <AuthContainer
        loginImage={
          <img src="/tenant/login_image.svg" alt="tenant_login_image" />
        }
        brandLogoWhite={<LogoGlobal />}
      >
        <Row>
          <Col
            xs={12}
            className="text-center d-flex align-items-center justify-content-center"
          >
            <img src="/tenant/logo_expanded.svg" alt="tenant_logo_expanded" />
          </Col>
          <p className="mb-0 text-center pt-4 pb-2 fs-1-1">
            {t("login_msg_1")} <b>{t("login_msg_2")}</b> {t("login_msg_3")}
          </p>

          <Col xs={12} className="pt-2">
            {errorForm && <CustomAlert type="error" text={errorForm} />}
            <Form onSubmit={handleSubmit(onLogin)}>
              <Row>
                <Col xs={12}>
                  <TextInput
                    control={control}
                    name="email"
                    type="text"
                    label={t("email_label")}
                  />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col xs={12}>
                  <TextInput
                    name="password"
                    type="password"
                    label={t("password")}
                    control={control}
                  />
                </Col>
              </Row>
              <div className="py-4">
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                  text={t("login")}
                  fullwidth
                />
              </div>
            </Form>
          </Col>
          <Col xs={12} className="pb-1 text-center">
            <button
              onClick={() => setOpenedModal(ModalType.FORGOT_PASSWORD)}
              className="text-center btn btn-link"
            >
              {t("forgot_password")}
            </button>
          </Col>
          <Col xs={12} className="text-center">
            <span> {t("no_account")}</span>{" "}
            <Link
              to="/auth/create-account"
              className="mb-0 text-center text-underline primary-link"
            >
              {t("create_account")}
            </Link>
            <hr />
          </Col>

          <Col xs={12}>
            <Button
              outlined
              text={t("pay_unaunthenticated")}
              onClick={enterWithoutAuthentication}
              fullwidth
            />
          </Col>
        </Row>
      </AuthContainer>
    </>
  );
};

export default Auth;
