import React from "react";
import { t } from "../utils/utils";
import Auth from "../views/auth/Auth";
import PayAccounts from "../views/paymentFlow";
import PaymentHistory from "../views/paymentHistory";
import CreateAccount from "../views/createAccount/CreateAccount";
import { ReactComponent as IconPay } from "../assets/svg/icons/pay.svg";
import { ReactComponent as IconCartola } from "../assets/svg/icons/cartola.svg";
import { Layouts, PP_ALLOW_LOGIN } from "./constants";
import ForgotPassword from "../views/forgotPassword/ForgotPassword";
import RestorePassword from "../views/forgotPassword/RestorePassword";
import ActivateAccount from "../views/createAccount/ActivateAccount";

export type ViewRouteType = {
  path: string;
  params?: string | null;
  titleMenu?: string;
  titleHeader?: string;
  icon?:
    | (() => JSX.Element)
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >
    | null;
  component: () => JSX.Element;
};

export type LayoutRouteType = {
  layout: string;
  views: ViewRouteType[];
};

/**
 * routes contains all the layouts and their views
 */

const authRoutes = {
  layout: Layouts.AUTH,
  views: [
    {
      path: "/login",
      component: Auth,
    },
    {
      path: "/create-account",
      component: CreateAccount,
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
    },
    {
      path: "/restore-password",
      params: "/:token?",
      component: RestorePassword,
    },
    {
      path: "/account-activation",
      params: "/:token?",
      component: ActivateAccount,
    },
  ],
};

const routes: LayoutRouteType[] = [
  ...(PP_ALLOW_LOGIN ? [authRoutes] : []),
  {
    layout: Layouts.DASHBOARD,
    views: [
      {
        path: "/index",
        titleMenu: t("pay"),
        titleHeader: t("pay_debts"),
        icon: IconPay,
        component: PayAccounts,
      },
    ],
  },
  {
    layout: Layouts.USER_DASHBOARD,
    views: [
      {
        path: "/index",
        titleMenu: t("pay"),
        titleHeader: t("pay_debts"),
        icon: IconPay,
        component: PayAccounts,
      },
      {
        path: "/payment-history",
        titleMenu: t("your_history"),
        titleHeader: t("your_payment_history"),
        icon: IconCartola,
        component: PaymentHistory,
      },
    ],
  },
];

export default routes;
