import { Col, Row } from "reactstrap";

import { SelectOptionType } from "@octano/global-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { paymentStatementRequest } from "./api";
import DataTable from "./parts/Table";
import { CollectedFilters, PaymentStatementResponse } from "./type";
import { SearchControls } from "./parts/SearchControls";

const ITEMS_PER_PAGE = 10;

const PaymentHistory = () => {
  const [tableData, setTableData] = useState<PaymentStatementResponse[]>([]);
  const [filters, setFilters] = useState<CollectedFilters>({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPayments, setTotalPayments] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const detailsOptions = useMemo<SelectOptionType[]>(
    () => [
      {
        value: "matriculation",
        label: "Matricula",
      },
      {
        value: "tariff",
        label: "Arancel completo",
      },
      {
        value: "tariffDownPayment",
        label: "Abono de arancel",
      },
      {
        value: "quota",
        label: "Cuota de arancel",
      },
    ],
    []
  );

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  const handleSearch = useCallback(
    async ({
      searchParams,
      page = 0,
    }: {
      searchParams?: CollectedFilters;
      page: number;
    }) => {
      if (isFetching) {
        return;
      }
      try {
        setIsFetching(true);
        const { data } = await paymentStatementRequest({
          ...searchParams,
          items: ITEMS_PER_PAGE,
          page: page,
        });
        if (data?.data) {
          setTableData(data?.data);
          setTotalPayments(data?.total);
        }
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  return (
    <>
      <SearchControls
        detailsOptions={detailsOptions}
        onSearch={(filters) => {
          setCurrentPage(0);
          setFilters(filters);
        }}
        onClear={handleClear}
      />
      <Row className="mb-5">
        <Col xs={12} className="pb-2">
          <DataTable
            data={tableData}
            page={currentPage}
            perPage={ITEMS_PER_PAGE}
            total={totalPayments}
            isLoadingResults={isFetching}
            onChangePage={setCurrentPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default PaymentHistory;
