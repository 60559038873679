import { Modal, TextInput } from "@octano/global-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { requestPasswordRecovery } from "../../api/request";
import CustomButton from "../../components/button/CustomButton";
import { useWindowSize } from "../../hooks/useWindowSize";
import { emailRegex, t } from "../../utils/utils";

const DEFAULT_VALUES = {
  recoveryEmail: "",
};

type FormValues = typeof DEFAULT_VALUES;

interface Props {
  isOpen: boolean;
  onCancel?: () => void;
  onEmailSent?: () => void;
}

export default function ForgotPasswordModal({
  isOpen,
  onCancel = () => null,
  onEmailSent = () => null,
}: Props) {
  const dictPrefix = "forgot-password-modal";
  const [isLoading, setIsLoading] = useState(false);

  const { isMobile } = useWindowSize();
  const { handleSubmit, control } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const requestForgotPasswordEmail = async (values: FormValues) => {
    setIsLoading(true);
    await requestPasswordRecovery(values.recoveryEmail).finally(() => {
      setIsLoading(false);
      onEmailSent();
    });
  };

  return (
    <Modal isOpen={isOpen} onClosed={() => null} toggle={() => onCancel()}>
      <form onSubmit={handleSubmit(requestForgotPasswordEmail)}>
        <div className="d-flex flex-column align-items-center text-center px-3 ">
          <img
            src="/tenant/logo_expanded.svg"
            alt="intitution_logo"
            width="220px"
            className="mb-3"
          />
          <h3 className="mb-3 text-dark">{t(`${dictPrefix}-title`)}</h3>
          <p className="mb-3" style={{ fontSize: "1.3rem", maxWidth: 380 }}>
            {t(`${dictPrefix}-description`)}
          </p>
        </div>

        <TextInput
          label={t("email")}
          placeholder={t("email")}
          name="recoveryEmail"
          rules={{
            required: t("required"),
            pattern: {
              value: emailRegex,
              message: t("login_invalid_email"),
            },
          }}
          control={control}
        />

        <div className="d-flex flex-wrap mt-4 w-100" style={{ gap: "0.8rem" }}>
          <CustomButton
            text={t("cancel")}
            colorType="secondary"
            onClick={onCancel}
            disabled={isLoading}
            style={{ width: isMobile ? "100%" : "calc(50% - 0.4rem)" }}
            className="m-0"
          />
          <CustomButton
            text={t("recover")}
            type="submit"
            loading={isLoading}
            style={{ width: isMobile ? "100%" : "calc(50% - 0.4rem)" }}
            className="m-0"
          />
        </div>
      </form>
    </Modal>
  );
}
