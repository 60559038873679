import { set as idbSet, get as idbGet, del as idbDel } from "idb-keyval";
import CryptoJS from "crypto-js";

const SECRET_KEY = "TuClaveSecreta";

const encryptValue = (value: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
};

const decryptValue = (encryptedValue: any) => {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const saveToIndexedDB = async (key: IDBValidKey, value: any) => {
  const encryptedValue = encryptValue(value);
  await idbSet(key, encryptedValue);
};

const getFromIndexedDB = async (key: IDBValidKey) => {
  const encryptedValue = await idbGet(key);
  if (encryptedValue === undefined) return undefined;
  return decryptValue(encryptedValue);
};

const delFromIndexedDB = async (key: IDBValidKey) => {
  await idbDel(key);
};

export { saveToIndexedDB, getFromIndexedDB, delFromIndexedDB };
