import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { requestEmailRestorePassword } from "../../api/request";
import AuthSingleCard from "../../components/auth/AuthSingleCard";
import CustomButton from "../../components/button/CustomButton";
import RestorePasswordForm from "./RestorePasswordForm";
import { ReactComponent as CheckCircleIcon } from "../../assets/svg/icons/check-circle.svg";
import { ReactComponent as WarningIcon } from "../../assets/svg/icons/alert.svg";
import { t } from "../../utils/utils";
import { REACT_APP_PP_CONTACT_EMAIL } from "../../config/constants";

const RestorePassword = () => {
  const history = useHistory();
  const [isReady, setIsReady] = useState(false);
  const { token } = useParams<{ token: string }>();
  const [successRestore, setSuccessRestore] = useState(false);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [loadingRetry, setLoadingRetry] = useState(false);

  const validateToken = useCallback(async () => {
    if (token) {
      setLoadingRetry(true);
      try {
        const res = await requestEmailRestorePassword(token);
        if (res.data.email) {
          setEmail(res.data.email);
        }
      } catch (error) {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
    setIsReady(true);
    setLoadingRetry(false);
  }, [token]);

  useEffect(() => {
    validateToken();
  }, [token, validateToken]);

  if (!isReady) {
    return <></>;
  }
  if (!isValid) {
    return (
      <AuthSingleCard
        icon={
          <WarningIcon className="svg-secondary" width="42px" height="42px" />
        }
        title={t("invalid_link_title")}
        message={t("invalid_link_msg") + REACT_APP_PP_CONTACT_EMAIL}
        displayLoginLink={false}
      />
    );
  }
  if (email === "") {
    return (
      <AuthSingleCard
        icon={
          <WarningIcon className="svg-secondary" width="42px" height="42px" />
        }
        title={t("error_load_link")}
        message={t("unexpected_error_msg")}
        displayLoginLink={false}
      >
        <Row className="px-1 px-sm-5 pt-3 pb-4">
          <Col xs={12}>
            <CustomButton
              type="button"
              text="Reintentar"
              onClick={validateToken}
              loading={loadingRetry}
              disabled={loadingRetry}
            />
          </Col>
        </Row>
      </AuthSingleCard>
    );
  }
  if (successRestore) {
    return (
      <AuthSingleCard
        icon={
          <CheckCircleIcon
            className="svg-secondary"
            width="41px"
            height="41px"
          />
        }
        title={t("restore_psw_success")}
        message={t("restore_psw_message")}
        displayLoginLink={false}
      >
        <Row className="px-1 px-sm-5">
          <Col xs={12}>
            <div className="py-4">
              <CustomButton
                type="button"
                text={t("go_to_login")}
                onClick={() => history.push("/auth/login")}
              />
            </div>
          </Col>
        </Row>
      </AuthSingleCard>
    );
  }

  return (
    <RestorePasswordForm
      email={email}
      token={token}
      setSuccessRestore={setSuccessRestore}
    />
  );
};

export default RestorePassword;
