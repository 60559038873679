import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

import { Debt, UserStore } from "./type";
import {
  SecureStorageStrategy,
  UnsafeStorageStrategy,
} from "../storageStrategy";

const userStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        user: { name: "", identifier: "" },
        debts: [],
        debtsPaid: [],
        setUser: (name: string, identifier: string) =>
          set({ user: { name, identifier } }),
        addDebts: (debts: Debt[]) => set(() => ({ debts })),
        updateDebt: (id: string, debt: Partial<Debt>) =>
          set((state) => ({
            debts: state.debts.map((d) =>
              d.id === id ? { ...d, ...debt } : d
            ),
          })),
        //Me permite seleccionar las deudas que marco en la tabla y calcula el total de la deuda seleccionada monto + interes
        checkDebts: (ids: string[]) =>
          set((state) => ({
            debts: state.debts.map((debt) => ({
              ...debt,
              total: ids.includes(debt.id)
                ? debt.amount + (debt.lateInterest || 0)
                : null,
              selected: ids.includes(debt.id),
            })),
          })),
        //agrego deudas pagadas si ya existia en el array no la vuelvo a insertar
        addDebtsPaid: (debtsPaid: Debt[]) =>
          set((state) => ({
            debtsPaid: [
              ...state.debtsPaid,
              ...debtsPaid.filter(
                (debt) =>
                  !state.debtsPaid.some(
                    (existingDebt) => existingDebt.id === debt.id
                  )
              ),
            ],
          })),
        removeDebtPaid: (debt: Debt) =>
          set((state) => ({
            debtsPaid: state.debtsPaid.filter((d) => d !== debt),
          })),
        reset: () => {
          userStore.persist.clearStorage();
        },
      }),
      {
        name: "user-storage",
        storage: createJSONStorage(() =>
          process.env.NODE_ENV !== "production"
            ? UnsafeStorageStrategy
            : SecureStorageStrategy
        ),
      }
    )
  )
);

export default userStore;
