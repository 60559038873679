import { AxiosResponse } from "axios";
import { DebtResponse } from "../../../types/debtType";
import { handleError } from "../../../utils/utils";
import API from "../../../api/api";

/**
 * Endpoint para obtener deudas de un usuario
 * @param rut rut del usuario sin puntos ni digito verificador
 */

const SEARCH_STEP = {
  //Endpoint para buscar deudas
  GET_DEBTS: (rut: string, recaptchaToken: string) =>
    `/payment-flow/search-step/${rut}?recaptchaToken=${recaptchaToken}`,
};

/**
 * Obtiene las deudas asociadas a un usuario
 * @param rut sin puntos ni digito verificador
 */
export const requestGetDebts = async (
  rut: string,
  recaptchaToken: string
): Promise<AxiosResponse<DebtResponse>> => {
  try {
    return await API.get(SEARCH_STEP.GET_DEBTS(rut, recaptchaToken));
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};
