import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import StepHeader from "../../components/paySteps/StepHeader";
import { PP_MAX_PAY_STEPS } from "../../config/constants";
import { useStepsSystemMemo, useUserState, useWindowSize } from "../../hooks";
import { useQuery } from "../../hooks/useQuery";
import useStepStore from "./store/step/stepStore";
import useUserStore from "./store/user/userStore";

const PayAccounts = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize();

  const { profile } = useUserState();

  const query = useQuery();

  const transactionStatus = query.get("transactionStatus");
  const webpayBuyOrder = query.get("webpayBuyOrder");

  const forcedStep = useMemo(() => {
    if (transactionStatus === "AUTHORIZED" && webpayBuyOrder) {
      return 2;
    }
    if (transactionStatus === "FAILED") {
      return 2;
    }
    return undefined;
  }, [transactionStatus, webpayBuyOrder]);

  const { currentStep, prevStep, setCurrentStep } = useStepStore((state) => ({
    currentStep: forcedStep !== undefined ? forcedStep : state.currentStep,
    setCurrentStep: state.setCurrentStep,
    prevStep: state.prevStep,
  }));

  const { debts, addDebtsPaid, addDebts } = useUserStore((state) => ({
    debts: state.debts,
    setUser: state.setUser,
    addDebts: state.addDebts,
    addDebtsPaid: state.addDebtsPaid,
  }));

  /**
   * Se redirecciona a esta url http://dominio/dashboard/index?transactionStatus=AUTHORIZED
   * cuando una transaccion  es exitosa. entonces las deudas pagadas. Aquellas a las que se
   * les calculó  el total... son insertadas en el array de deudas pagadas
   */
  useEffect(() => {
    if (transactionStatus === "AUTHORIZED" && debts.length > 0) {
      addDebtsPaid(debts.filter((d) => d.total && d.total > 0));
    }
  }, [
    debts,
    transactionStatus,
    addDebtsPaid,
    setCurrentStep,
    webpayBuyOrder,
    history,
  ]);

  const loggedInAndCanGoBackwards = profile && currentStep > 1;
  const notLoggedAndCanGoBackwards = !profile && currentStep > 0;

  const handleGoToPreviousStep = () => {
    if (currentStep - 1 === 0) {
      addDebts([]);
    }
    if (currentStep === PP_MAX_PAY_STEPS - 1) {
      history.push(pathname);
    }
    prevStep();
  };

  const stepsComponent = useStepsSystemMemo()["UFRO"].steps;
  const StepComponent = stepsComponent[Math.max(currentStep, 0)].component;

  return (
    <>
      <StepHeader
        currentStep={Math.max(currentStep, 0)}
        isMobile={isMobile}
        loggedInAndCanGoBackwards={loggedInAndCanGoBackwards}
        notLoggedAndCanGoBackwards={notLoggedAndCanGoBackwards}
        handleGoToPreviousStep={handleGoToPreviousStep}
      />

      <StepComponent />
    </>
  );
};

export default PayAccounts;
