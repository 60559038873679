import { BaseException, TransalationKeys } from "./base.exception";

export enum CreateAccountExceptionCode {
  RUT_EXISTS = "RUT_EXISTS",
  EMAIL_EXISTS = "EMAIL_EXISTS",
  INVALID_RUT = "INVALID_RUT",
  INVALID_EMAIL = "INVALID_EMAIL",
  FAILED_CREATION = "FAILED_CREATION",
}

export class CreateAccountException extends BaseException<CreateAccountExceptionCode> {
  defaultTranslationKeys(): TransalationKeys {
    return {
      [CreateAccountExceptionCode.RUT_EXISTS]: "rut_already_exists",
      [CreateAccountExceptionCode.EMAIL_EXISTS]: "email_already_exists",
      [CreateAccountExceptionCode.INVALID_RUT]: "validate_rut",
      [CreateAccountExceptionCode.INVALID_EMAIL]: "invalid_email",
      [CreateAccountExceptionCode.FAILED_CREATION]: "unexpected_error_msg",
    };
  }
}
