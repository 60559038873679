import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  SecureStorageStrategy,
  UnsafeStorageStrategy,
} from "../storageStrategy";
interface StepStore {
  currentStep: number;
  resetStep: () => void;
  nextStep: () => void;
  prevStep: () => void;
  setCurrentStep: (step: number) => void;
}
const useStepStore = create<StepStore>()(
  devtools(
    persist(
      (set) => ({
        currentStep: 0,
        nextStep: () =>
          set((state) => ({ currentStep: state.currentStep + 1 })),
        prevStep: () =>
          set((state) => ({ currentStep: state.currentStep - 1 })),
        setCurrentStep: (step) => {
          return set({ currentStep: step });
        },
        resetStep: () => set({ currentStep: 0 }),
      }),
      {
        name: "step-storage",
        storage: createJSONStorage(() =>
          process.env.NODE_ENV !== "production"
            ? UnsafeStorageStrategy
            : SecureStorageStrategy
        ),
      }
    )
  )
);

export default useStepStore;
