import { UserAction, UserActionTypes, UserState } from "../types/userTypes";
import { getJWT } from "../utils/auth";

export const initialState: UserState = {
  isLogged: getJWT() ? true : false,
  profile: null,
  isSessionExpired: false,
};

const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionTypes.RESET_STATE:
      return {
        ...initialState,
        isLogged: false,
      };

    case UserActionTypes.SET_IS_LOGGED:
      return {
        ...state,
        isLogged: action.payload,
      };

    case UserActionTypes.SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case UserActionTypes.SET_IS_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
