export interface PaymentStatementResponse {
  id: string;
  reference: string;
  matriculationDate?: Date | null | undefined;
  program: string;
  period: string;
  installmentNumber: number;
  maxInstallment: number;
  lateInterest: number;
  installmentDueDate: Date | null;
  type: string;
  documentTypeCode: string;
  paymentType: string;
  salenote: string | null;
  contract?: string | null | undefined;
  amount: number;
  transbankTransactionId: string | null;
  createdAt: Date;
  updatedAt: Date;
  transbankTransaction: string;
  detail: {
    debtOwner: { name: string; identifier: string };
    student: { name: string; identifier: string };
  } | null;
  //adicionales agregados en el map
  transactionDate?: Date;
  voucherUrl?: string;
}

export enum CollectionTypeCode {
  TariffDownPayment = "tariffDownPayment", // Abono de Arancel (credito)
  Tariff = "tariff", // Arancel Completo (contado)
  Quota = "quota", // Cuota de Arancel (credito)
  Matriculation = "matriculation", // Matricula
}

export const CollectionTypeEquivalent: Record<string, string> = {
  [CollectionTypeCode.Matriculation]: "matricula",
  [CollectionTypeCode.Tariff]: "arancel",
  [CollectionTypeCode.TariffDownPayment]: "abonoArancel",
  [CollectionTypeCode.Quota]: "cuota",
};

export interface CollectedFilters {
  search?: string;
  reference?: string;
  paymentDate?: string[];
  type?: any;
}

export type PaginatedResponse<T> = {
  data: T[];
  total: number;
};
