import { AxiosResponse } from "axios";
import API from "../../../api/api";
import { handleError } from "../../../utils/utils";
import { RequestPayDebtsParams, RequestPayDebtsResponse } from "../types";

const PAYMENT_STEP = {
  PAY: "/payment-flow/payment-step",
};

const PAYMENT_STEP_AUTHENTICATED = {
  PAY: "/payment-flow/payment-step/authenticated",
};

export const payDebtsRequest = async (
  body: RequestPayDebtsParams,
  isAuthenticated: boolean
): Promise<AxiosResponse<RequestPayDebtsResponse>> => {
  try {
    const url = isAuthenticated
      ? PAYMENT_STEP_AUTHENTICATED.PAY
      : PAYMENT_STEP.PAY;
    return await API.post(url, {
      ...body,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};
