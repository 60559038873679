import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { CustomToastContainer } from "./components/toast/CustomToast";
import DashboardLayout from "./layouts/DashboardLayout";
import AuthLayout from "./layouts/AuthLayout";
import AppProvider from "./providers/AppProvider";
import UserProvider from "./providers/UserProvider";
import WebpayErrorRedirect from "./components/webpayError/WebpayErrorRedirect";
import { Layouts, PP_ALLOW_LOGIN } from "./config/constants";
import { Provider } from "react-redux";
import store from "./store/index";
import { ToastProvider } from "@octano/global-ui";

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <AppProvider>
          <UserProvider>
            <Router>
              <Switch>
                <Route
                  path="/webpayError"
                  render={() => <WebpayErrorRedirect />}
                />
                {PP_ALLOW_LOGIN ? (
                  <Route
                    path={Layouts.AUTH}
                    render={(props: any) => <AuthLayout {...props} />}
                  />
                ) : null}
                <Route
                  path={Layouts.DASHBOARD}
                  render={(props: any) => (
                    <DashboardLayout layout={Layouts.DASHBOARD} {...props} />
                  )}
                />
                <Route
                  path={Layouts.USER_DASHBOARD}
                  render={(props: any) => (
                    <DashboardLayout
                      layout={Layouts.USER_DASHBOARD}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={Layouts.PAYMENT_HISTORY}
                  render={(props: any) => (
                    <DashboardLayout
                      layout={Layouts.PAYMENT_HISTORY}
                      {...props}
                    />
                  )}
                />
                <Redirect
                  from="*"
                  to={PP_ALLOW_LOGIN ? "/auth/index" : "/dashboard/index"}
                />
              </Switch>
            </Router>
          </UserProvider>
          <CustomToastContainer />
        </AppProvider>
      </ToastProvider>
    </Provider>
  );
}

export default App;
