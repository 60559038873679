import { Button, TextInput } from "@octano/global-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { clean, validate } from "rut.js";
import { t } from "../../utils/utils";

const DEFAULT_VALUES = {
  name: "",
  lastName: "",
  phone: "",
  email: "",
  password: "",
  repeatPassword: "",
  rut: "",
};

export type FormValues = typeof DEFAULT_VALUES;

const getRequiredValueError = (value: string, error: string) => {
  if (value.trim() === "") {
    return error;
  }
  return undefined;
};

export type InputsCreateAccount = {
  rut: string;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  repeatPassword: string;
};

type CreateAccountFormProps = {
  step: 1 | 2;
  setStep: React.Dispatch<React.SetStateAction<2 | 1>>;
  onCreateAccount: (values: InputsCreateAccount) => void;
  initialValues?: Partial<FormValues>;
};

const CreateAccountForm = ({
  step,
  onCreateAccount,
  setStep,
  initialValues,
}: CreateAccountFormProps) => {
  const {
    handleSubmit,
    getValues,
    trigger,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<InputsCreateAccount>({
    mode: "onChange",
    defaultValues: {
      ...DEFAULT_VALUES,
      ...initialValues,
    },
  });

  const handleCreateAccount = (values: FormValues) => {
    return onCreateAccount({
      ...values,
      rut: initialValues?.rut || values.rut,
    });
  };

  return (
    <Form onSubmit={handleSubmit(handleCreateAccount)}>
      <div className={step === 1 ? "" : "d-none"}>
        <TextInput
          label={t("name")}
          name="name"
          rules={{
            required: t("required_field"),
            validate: (value) =>
              getRequiredValueError(value, t("invalid_name")),
          }}
          control={control}
        />

        <TextInput
          label={t("lastname")}
          name="lastName"
          rules={{
            required: t("required_field"),
            validate: (value) => {
              if (value.trim() === "") {
                return t("invalid_lastname");
              }
              return undefined;
            },
          }}
          control={control}
        />

        <TextInput
          label={t("phone")}
          name="phone"
          formatter="phone"
          rules={{
            required: t("required_field"),
            validate: (value) =>
              getRequiredValueError(value, t("invalid_phone")),
            pattern: {
              //eslint-disable-next-line
              value: /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/,
              message: t("invalid_phone"),
            },
          }}
          control={control}
        />

        <div className="py-4">
          <Button
            type="button"
            disabled={!isValid || isSubmitting}
            text={t("next")}
            onClick={() => setStep(2)}
            fullwidth
          />
        </div>
      </div>

      {step === 2 && (
        <div>
          <TextInput
            label={t("rut")}
            name="rut"
            formatter="rut"
            rules={{
              required: t("rut_required"),
              validate: (value) => {
                if (!validate(clean(value))) {
                  return t("validate_rut");
                }
                return undefined;
              },
            }}
            disabled={!!initialValues?.rut}
            control={control}
          />

          <TextInput
            label={t("email")}
            name="email"
            rules={{
              required: t("required_field"),
              pattern: {
                //eslint-disable-next-line
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("invalid_email"),
              },
            }}
            control={control}
          />
          <TextInput
            label={t("password")}
            name="password"
            type="password"
            rules={{
              required: t("required_field"),
              minLength: {
                value: 8,
                message: t("min_length_password"),
              },
            }}
            control={control}
            onChange={() => {
              if (getValues("repeatPassword") !== "") {
                trigger("repeatPassword");
              }
            }}
            tooltip={t("min_length_password")}
          />
          <TextInput
            type="password"
            label={t("repeat_password")}
            name="repeatPassword"
            rules={{
              required: t("required_field"),
              validate: (value: string) => {
                if (value !== getValues("password")) {
                  return t("validate_repeat_password");
                }
                return undefined;
              },
              minLength: {
                value: 8,
                message: t("min_length_password"),
              },
            }}
            control={control}
          />

          <div className="py-4">
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              text={t("button_create_account")}
              fullwidth
            />
          </div>
        </div>
      )}
    </Form>
  );
};

export default CreateAccountForm;
