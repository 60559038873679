import React from "react";

import ResultAlert from "../../../../components/error/ResultAlert";

type WebpayErrorProps = {
  title: string;
  instructions: string;
  details?: JSX.Element | null;
};
const WebpayError = (props: WebpayErrorProps) => {
  return <ResultAlert type="warning" {...props} />;
};

export default WebpayError;
