import { Button, TextInput } from "@octano/global-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { requestPasswordRecovery } from "../../api/request";
import { ReactComponent as CheckCircleIcon } from "../../assets/svg/icons/check-circle.svg";
import CustomAlert from "../../components/alert/CustomAlert";
import AuthSingleCard from "../../components/auth/AuthSingleCard";
import CustomButton from "../../components/button/CustomButton";
import { t } from "../../utils/utils";

type InputsForgot = {
  email: string;
};

const ForgotPassword = () => {
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<InputsForgot>({
    mode: "onChange",
  });
  const history = useHistory();
  const [successRecovery, setSuccessRecovery] = useState(false);
  const [errorRecovery, setErrorRecovery] = useState(false);

  const onSubmit = async (values: InputsForgot) => {
    try {
      await requestPasswordRecovery(values.email);
      setSuccessRecovery(true);
    } catch (error) {
      setErrorRecovery(true);
    }
  };

  if (successRecovery) {
    return (
      <AuthSingleCard
        icon={
          <CheckCircleIcon
            className="svg-secondary"
            width="41px"
            height="41px"
          />
        }
        title={"¡Listo!"}
        message={
          "Revisa tu correo y sigue los pasos para recuperar tu contraseña."
        }
      >
        <Row className="px-1 px-sm-5">
          <Col xs={12}>
            <div className="py-4">
              <CustomButton
                type="button"
                text={t("go_to_login")}
                onClick={() => history.push("/auth/login")}
              />
            </div>
          </Col>
        </Row>
      </AuthSingleCard>
    );
  }

  return (
    <AuthSingleCard title={t("psw_recovery")} message={t("psw_recovery_msg")}>
      <Row className="px-1 px-sm-5">
        {errorRecovery && (
          <Col xs={12} className="pt-2">
            <CustomAlert type="error" text={t("unexpected_error_msg")} />
          </Col>
        )}
        <Col xs={12} className="pt-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label={t("email")}
              name="email"
              rules={{
                required: t("required_field"),
                pattern: {
                  //eslint-disable-next-line
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("invalid_email"),
                },
              }}
            />
            <div className="py-4">
              <Button
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                text={t("send")}
                fullwidth
              />
            </div>
          </Form>
        </Col>
      </Row>
    </AuthSingleCard>
  );
};

export default ForgotPassword;
