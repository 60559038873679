import Box from "@mui/material/Box";
import { DataGrid, gridClasses, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useRef } from "react";
import {
  PP_BRAND_COLORS_PRIMARY,
  PP_BRAND_COLORS_SECONDARY,
  REACT_APP_PP_PAY_INSTALLMENTS_PARTIALLY,
} from "../../../../../config/constants";
import currencyFormat from "../../../../../utils/currency-format";
import useUserStore from "../../../store/user/userStore";
import useColumns from "./useColumns";

export default function DataTable() {
  const apiRef = useGridApiRef();
  const didMount = useRef(false);
  const { debts, debtsPaid, checkDebts, updateDebt } = useUserStore(
    (state) => ({
      debts: state.debts,
      checkDebts: state.checkDebts,
      debtsPaid: state.debtsPaid,
      updateDebt: state.updateDebt,
    })
  );

  useEffect(() => {
    if (!didMount.current) {
      checkDebts([]);
    } else {
      didMount.current = true;
    }
  }, [checkDebts]);

  const columns = useColumns();

  return (
    <Box
      sx={{
        paddingLeft: 5,
        paddingRight: 5,
        width: "100%",
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-top": {
          backgroundColor: PP_BRAND_COLORS_SECONDARY,
          background: PP_BRAND_COLORS_SECONDARY,
          color: "white",
        },
        [`& .${gridClasses.checkboxInput}`]: {
          color: PP_BRAND_COLORS_PRIMARY,
        },
      }}
    >
      <DataGrid
        apiRef={apiRef}
        autoHeight
        hideFooterPagination
        rows={debts.map((debt) => ({
          ...debt,
          inProgress: debtsPaid.some((d) => d.key === debt.key),
        }))}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onRowClick={(row) => {
          row.row.field === "total" &&
            REACT_APP_PP_PAY_INSTALLMENTS_PARTIALLY &&
            !debtsPaid.some((d) => d.key === row.row.key) &&
            apiRef.current.startCellEditMode({
              id: String(row.id),
              field: row.row.field,
            });
        }}
        onProcessRowUpdateError={(error) => {
          console.log(error);
        }}
        onRowSelectionModelChange={(rowSelectionModel) => {
          checkDebts(rowSelectionModel as string[]);
        }}
        isRowSelectable={(debt) => {
          return !debt.row.inProgress;
        }}
        isCellEditable={(debt) => {
          return !debt.row.inProgress;
        }}
        processRowUpdate={(params) => {
          updateDebt(params.id, { total: params.total, id: params.id });
          return params;
        }}
        slots={{
          footer: () => (
            <div
              style={{
                backgroundColor: PP_BRAND_COLORS_SECONDARY,
                height: 50,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                color: "white",
                fontSize: "1.25rem",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Total a pagar:{" "}
              {currencyFormat(
                debts.reduce(
                  (total, debt) =>
                    debt.selected ? total + (debt.total || 0) : total,
                  0
                )
              )}
            </div>
          ),
        }}
      />
    </Box>
  );
}
