import API from "../../api/api";
import { PaginatedResponse, PaymentStatementResponse } from "./type";

const PAYMENT_STATEMENT = {
  BASE: "/payment-statement",
};

export const paymentStatementRequest = async (filter: {
  items: number;
  page: number;
}) => {
  const method = "GET";
  const params = {
    ...filter,
    items_per_page: filter.items,
  };
  return API.get<PaginatedResponse<PaymentStatementResponse>>(
    PAYMENT_STATEMENT.BASE,
    {
      params,
      method,
    }
  );
};
