/**
 * Endpoint para obtener las deudas pagadas por el usuario
 */
export const GET_PAYMENT_HISTORY = `/debts/payment-history`;

/**
 * Endpoint para crear una cuenta de usuario
 */
export const CREATE_ACCOUNT = "/user/create-account";

/**
 * Endpoint para crear una cuenta de usuario
 */
export const ACTIVATE_ACCOUNT = (token: string) =>
  `/user/activate-account?token=${token}`;

/**
 * Endpoint para iniciar sesión
 */
export const LOGIN = "/auth/signin";

/**
 * Endpoint para obtener la información de perfil del usuario autenticado
 */
export const CHANGE_PASSWORD = "/user/password";

/**
 * Endpoint para obtener el listado de los ruts pagados anteriormente por el usuario autenticado
 */
export const RECENT_ACCOUNTS = "/debts/last-payed-ruts";

/**
 * Endpoint para recuperar la clave del usuario (Envía el link para restablecer la contraseña)
 */
export const PASSWORD_RECOVERY = "/auth/password-recovery";

/**
 * Endpoint para restablecer la contraseña
 */
export const RESTORE_PASSWORD = "/auth/restore-password";

/**
 * Endpoint para recuperar el email al que se le restablecerá la contraseña
 */
export const EMAIL_RESTORE_PASSWORD = "/auth/email-restore-password";

/**
 * Endpoint para descargar el comprobante en PDF por usuario logueado
 */
export const DOWNLOAD_PDF_VOUCHER_USER = (buyOrder: string) =>
  `/voucher/${buyOrder}/download-user/pdf`;

/**
 * Endpoint para verificar si un RUT se encuentra registrado en el ERP y por ende puede registrarse en el portal
 */
export const VERIFY_RUT = `/auth/search`;
