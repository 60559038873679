import React from "react";
import ReactDOM from "react-dom/client";
import numeral from "numeral";
import "./assets/scss/styles.scss";
import "./config/language";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getOrdinal } from "./utils/utils";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

numeral.register("locale", "cl", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "b",
    trillion: "t",
  },
  ordinal: getOrdinal,
  currency: {
    symbol: "$",
  },
});

// switch between locales
numeral.locale("cl");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
