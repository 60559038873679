import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@octano/global-ui";
import { REACT_APP_PP_PAY_INSTALLMENTS_PARTIALLY } from "../../../../../config/constants";
import currencyFormat from "../../../../../utils/currency-format";

export default function useColumns(): GridColDef[] {
  return [
    {
      field: "key",
      headerName: "N Dcto",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "documentType",
      headerName: "Tipo",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "detail",
      headerName: "Detalle",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "installmentNumber",
      headerName: "N cuota",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "installmentDueDate",
      headerName: "Fecha venc.",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params: string) => {
        return new Date(params).toLocaleDateString();
      },
    },
    {
      field: "amount",
      headerName: "Monto Capital",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      valueFormatter: (value: number) => {
        return currencyFormat(value);
      },
    },
    {
      field: "interest",
      headerName: "Interés",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 100,
      valueFormatter: (value: number) => {
        return currencyFormat(value ?? 0);
      },
    },
    {
      field: "total",
      headerName: "Total",
      editable: REACT_APP_PP_PAY_INSTALLMENTS_PARTIALLY,
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 100,
      valueFormatter: (value: number) => {
        return value ? currencyFormat(value) : null;
      },
    },
    {
      field: "inProgress",
      headerName: "En progreso",
      type: "custom",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        if (params.value === true) {
          return <Icon name="circle" />;
        }
        return <></>;
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      minWidth: 150,
      flex: 1,
    },
    ...(process.env.NODE_ENV !== "production"
      ? ([
          {
            field: "selected",
            headerName: "Seleccionada",
            type: "boolean",
            headerAlign: "center",
            align: "center",
            width: 150,
          },
        ] as GridColDef[])
      : []),
  ];
}
