import { useForm } from "react-hook-form";
import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
} from "@octano/global-ui";
import { Col, Row } from "reactstrap";
import dayjs from "dayjs";
import { CollectedFilters } from "../../type";

type FormValues = {
  search: string;
  reference: string;
  paymentDateStart: string | null;
  paymentDateEnd: string | null;
  type: SelectOptionType | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  search: "",
  reference: "",
  paymentDateStart: null,
  paymentDateEnd: null,
  type: null,
};

interface Props {
  detailsOptions: SelectOptionType[];
  onSearch?: (sp: CollectedFilters) => void;
  onClear?: () => void;
}

export function SearchControls({
  detailsOptions,
  onSearch = () => null,
  onClear = () => null,
}: Props) {
  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleSearch = (values: FormValues) => {
    const paymentDate = [];
    if (values?.paymentDateStart?.trim() && values?.paymentDateEnd?.trim()) {
      paymentDate.push(
        ...[
          dayjs(values?.paymentDateStart?.trim())
            .startOf("day")
            .format("YYYY-MM-DD"),
          dayjs(values?.paymentDateEnd?.trim())
            .endOf("day")
            .format("YYYY-MM-DD"),
        ]
      );
    } else if (
      values?.paymentDateStart?.trim() &&
      !values?.paymentDateEnd?.trim()
    ) {
      paymentDate.push(
        ...[
          dayjs(values?.paymentDateStart?.trim())
            .startOf("day")
            .format("YYYY-MM-DD"),
          dayjs(values?.paymentDateStart?.trim())
            .endOf("day")
            .format("YYYY-MM-DD"),
        ]
      );
    } else if (
      !values?.paymentDateStart?.trim() &&
      values?.paymentDateEnd?.trim()
    ) {
      paymentDate.push(
        ...[
          dayjs(values?.paymentDateEnd?.trim()).startOf("day").toISOString(),
          dayjs(values?.paymentDateEnd?.trim()).endOf("day").toISOString(),
        ]
      );
    }
    onSearch({
      search: values?.search?.trim() ?? undefined,
      reference: values?.reference?.trim() ?? undefined,
      type: values?.type?.value ?? undefined,
      paymentDate: paymentDate?.length ? paymentDate : undefined,
    });
  };

  const handleClear = () => {
    reset(DEFAULT_FORM_VALUES);
    onClear();
  };

  return (
    <Row className="mb-5 px-5">
      <Col xs={12} sm={6} md={6} lg={3}>
        <TextOutlinedInput
          name="search"
          control={control}
          label="RUT"
          placeholder="Busca por RUT"
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={3}>
        <TextOutlinedInput
          name="reference"
          control={control}
          label="Nº Dcto"
          placeholder="Busca un Nº de Dcto"
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={3}>
        <TextOutlinedInput
          control={control}
          type={"date" as any}
          name="paymentDateStart"
          label="Fecha de pago (Desde)"
          placeholder="Desde"
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={3}>
        <TextOutlinedInput
          control={control}
          type={"date" as any}
          name="paymentDateEnd"
          label="Fecha de pago (Hasta)"
          placeholder="Hasta"
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={3}>
        <OutlinedSelect
          name="type"
          label="Detalle"
          placeholder="Busca por detalle"
          options={detailsOptions}
          control={control}
          disabled={!detailsOptions?.length}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={9}>
        <div className="d-flex flex-column w-100 align-items-end mt-4">
          <div>
            <Button
              className="mx-1"
              type="button"
              size="md"
              text="BUSCAR"
              onClick={handleSubmit(handleSearch)}
            />
            <Button
              className="mx-1"
              type="button"
              size="md"
              text="LIMPIAR"
              outlined
              onClick={handleClear}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
