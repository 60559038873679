import { Col, Form, Row } from "reactstrap";
import { clean, validate as validateRut } from "rut.js";
import { t } from "../../../../utils/utils";

import { TextInput } from "@octano/global-ui";
import { AnonymousUserInputsProps } from "../../../../views/paymentFlow/paymentStep/types";

const AnonymousUserInputs = ({
  isLogged,
  control,
}: AnonymousUserInputsProps) => {
  return !isLogged ? (
    <Form className="container-max-600 ml-auto pb-2 pt-5 mr-5">
      <Row>
        <Col sm={6} className="pr-3 pb-3 pr-sm-1">
          <TextInput
            label={t("label_your_rut")}
            name="payerRut"
            formatter="rut"
            rules={{
              required: t("rut_required"),
              validate: (value: string) => {
                if (!validateRut(clean(value))) {
                  return t("invalid_rut");
                } else {
                  return undefined;
                }
              },
            }}
            control={control}
          />
        </Col>
        <Col sm={6} className="pr-3 pr-sm-3">
          <TextInput
            label={t("label_your_email")}
            name="payerEmail"
            rules={{
              required: t("required_field"),
              pattern: {
                //eslint-disable-next-line
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("invalid_email"),
              },
            }}
            control={control}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="mb-0 text-left pt-1 pb-2 fs-1-1">
            {t("anonymous_rut_email_instructions")}
          </p>
        </Col>
      </Row>
    </Form>
  ) : null;
};

export default AnonymousUserInputs;
