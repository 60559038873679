import Box from "@mui/material/Box";
import { DataGrid, gridClasses, useGridApiRef } from "@mui/x-data-grid";

import {
  PP_BRAND_COLORS_PRIMARY,
  PP_BRAND_COLORS_SECONDARY,
} from "../../../../config/constants";
import useColumns from "./useColumns";
import { PaymentStatementResponse } from "../../type";

export default function DataTable({
  data,
  total,
  page = 0,
  perPage = 10,
  isLoadingResults = false,
  onChangePage,
}: {
  data: PaymentStatementResponse[];
  page?: number;
  perPage?: number;
  total?: number;
  isLoadingResults?: boolean;
  onChangePage?: (next: number) => void;
}) {
  const apiRef = useGridApiRef();
  const columns = useColumns();

  return (
    <Box
      sx={{
        paddingLeft: 5,
        paddingRight: 5,
        width: "100%",
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-top": {
          backgroundColor: PP_BRAND_COLORS_SECONDARY,
          background: PP_BRAND_COLORS_SECONDARY,
          color: "white",
        },
        [`& .${gridClasses.checkboxInput}`]: {
          color: PP_BRAND_COLORS_PRIMARY,
        },
      }}
    >
      <DataGrid
        apiRef={apiRef}
        autoHeight
        columns={columns}
        rows={data}
        paginationMode="server"
        rowCount={total}
        loading={isLoadingResults}
        paginationModel={{
          page,
          pageSize: perPage,
        }}
        onPaginationModelChange={(model) => {
          !!onChangePage && onChangePage(model.page);
        }}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnSorting
        localeText={{
          noRowsLabel: "No hay resultados",
          MuiTablePagination: {
            labelRowsPerPage: "Filas por página",
          },
          columnMenuManageColumns: "Gestionar columnas",
          columnMenuFilter: "Filtrar",
          columnMenuShowColumns: "Mostrar columnas",
          columnMenuHideColumn: "Ocultar",
          columnMenuSortAsc: "Ordenar ascendente",
          columnMenuSortDesc: "Ordenar descendente",
        }}
      />
    </Box>
  );
}
