import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CustomAlert from "../../components/alert/CustomAlert";
import CreateAccountForm, {
  InputsCreateAccount,
  FormValues,
} from "./CreateAccountForm";
import { ReactComponent as CheckCircle } from "../../assets/svg/icons/check-circle.svg";
import { requestCreateAccount } from "../../api/request";
import { t } from "../../utils/utils";
import AuthSingleCard from "../../components/auth/AuthSingleCard";
import RutSearch from "./RutSearch";
import { Button } from "@octano/global-ui";
import { format } from "rut.js";
import { CreateAccountException } from "../../api/exceptions/create-account.exception";

const CreateAccount = () => {
  const history = useHistory();
  const [step, setStep] = useState<1 | 2>(1);
  const [errorCreate, setErrorCreate] = useState<string>();
  const [successAccount, setSuccessAccount] = useState(false);
  const [checkedRut, setCheckedRut] = useState(false);
  const [formInitialValues, setFormInitialValues] =
    useState<Partial<FormValues>>();

  const onCreateAccount = async (values: InputsCreateAccount) => {
    try {
      const res = await requestCreateAccount(
        format(values.rut.replace(/[.,-]/g, "")),
        values.name.trim(),
        values.lastName.trim(),
        values.phone.trim(),
        values.email,
        values.password
      );
      if (res) {
        setSuccessAccount(true);
      }
    } catch (error: any) {
      const parsedError = new CreateAccountException(error);
      setErrorCreate(t(parsedError.getTranslationKey()));
    }
  };

  return (
    <AuthSingleCard
      title={successAccount ? undefined : "Creación de cuenta"}
      message={
        successAccount
          ? undefined
          : "Para crear tu cuenta debes ingresar la siguiente información:"
      }
      displayLoginLink={!successAccount}
    >
      {successAccount ? (
        // MENSAJE DE ÉXITO AL CREAR LA CUENTA
        <Row className="px-5 pb-5">
          <Col xs={12} className="text-center pt-5 pb-4">
            <CheckCircle className="svg-secondary" width="40" height="40" />
          </Col>
          <Col xs={12} className="text-center">
            <span className="fs-1-4 color-dark">
              {t("create_account_success_title")}
            </span>
            <p className="color-light fs-1-2 py-3">
              {t("create_account_success_content")}
            </p>
          </Col>
          <Col xs={12}>
            <Button
              type="button"
              text={t("signin")}
              onClick={() => history.push("/auth/login")}
              fullwidth
            />
          </Col>
        </Row>
      ) : (
        <>
          {/* Textos sobre el formulario */}
          <Row className="text-center px-4 px-sm-5">
            <Col xs={12} className="pt-4">
              <span className="fs-1-4 color-dark"></span>
            </Col>
            <Col xs={12} className="pt-3">
              <p className="color-light fs-1-2"></p>
            </Col>
          </Row>
          <Row className="px-3 px-sm-4">
            {errorCreate && (
              <Col xs={12} className="pb-3">
                <CustomAlert type="error" text={errorCreate} />
              </Col>
            )}

            <Col xs={12}>
              {checkedRut ? (
                <CreateAccountForm
                  step={step}
                  setStep={setStep}
                  onCreateAccount={onCreateAccount}
                  initialValues={formInitialValues}
                />
              ) : (
                <RutSearch
                  onRutVerificationSuccess={(personData) => {
                    setFormInitialValues(() => ({
                      ...personData,
                    }));
                    setCheckedRut(() => true);
                  }}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </AuthSingleCard>
  );
};

export default CreateAccount;
