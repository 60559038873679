import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import OptionsMenu from "./OptionsMenu";
import MenuLogo from "./MenuLogo";
import { ReactComponent as MenuOpenIcon } from "../../assets/svg/icons/menu-open.svg";
import { ReactComponent as MenuCloseIcon } from "../../assets/svg/icons/menu-close.svg";
import { ReactComponent as LogoutIcon } from "../../assets/svg/icons/logout.svg";
import { useSideBar, useUserState } from "../../hooks";
import { t } from "../../utils/utils";
import { Layouts, PP_ALLOW_LOGIN } from "../../config/constants";
import { removeJWT } from "../../utils/auth";

type LateralMenuProps = {
  layout: Layouts.DASHBOARD | Layouts.USER_DASHBOARD;
};
const LateralMenu = ({ layout }: LateralMenuProps) => {
  const history = useHistory();
  const { isLogged, resetUserState, setIsLogged } = useUserState();
  const { isMenuOpen, toggleMenu, setIsMenuOpen } = useSideBar();

  const logout = () => {
    if (isLogged) {
      removeJWT();
      resetUserState();
      setIsLogged(false);
    } else {
      history.push("/auth/login");
    }
    setIsMenuOpen(null);
  };

  const getStatusAnimation = (): string => {
    // Se valida si es null para que comience cerrada sin hacer la animación
    if (isMenuOpen === null) {
      return "";
    }
    if (isMenuOpen) {
      return "open";
    }
    return "closed";
  };

  return (
    <>
      <div
        className={`bg-lateral-menu ${isMenuOpen ? "active" : "inactive"}`}
        onClick={toggleMenu}
      />
      <div className={`p-0 vh-100 lateral-menu ${getStatusAnimation()}`}>
        <div
          className="vh-100 d-flex flex-column flex-grow-1"
          style={{ overflowX: "hidden" }}
        >
          {/* ICON TO OPEN AND CLOSE THE MENU */}
          <div className={`p-3 pt-4 text-${isMenuOpen ? "right" : "center"}`}>
            <div className="cursor-pointer icon-bars" onClick={toggleMenu}>
              {isMenuOpen ? <MenuOpenIcon /> : <MenuCloseIcon />}
            </div>
          </div>
          {/* BRAND LOGO*/}
          <div className="p-3" style={{ height: "7rem", whiteSpace: "nowrap" }}>
            <MenuLogo />
          </div>
          {/* MENU OPTIONS*/}
          <div
            className="text-center overflow-scroll-hidden d-flex flex-column flex-grow-1"
            style={{ minHeight: "40px" }}
          >
            <OptionsMenu pathLayout={layout} />
          </div>

          {/* LOGOUT BUTTON */}
          {PP_ALLOW_LOGIN ? (
            <div>
              <Button
                block
                type="button"
                onClick={logout}
                className="btn-logout text-center"
                style={{ whiteSpace: "nowrap" }}
              >
                <LogoutIcon className="svg-primary" width="1em" height="1em" />
                {isMenuOpen && (
                  <span className="pl-3 btn-inner--text">
                    {isLogged ? t("logout") : t("go_back_login")}
                  </span>
                )}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default LateralMenu;
