import { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { Button } from "@octano/global-ui";

import CustomButton from "../../../components/button/CustomButton";
import { useUserState } from "../../../hooks";
import { getAnonymousUserEmail, t } from "../../../utils/utils";
import useStepStore from "../store/step/stepStore";
import WebpayError from "./parts/WebpayError";
import WebpaySuccessInfo from "./parts/WebpaySuccessInfo";
import { requestGetPaymentResult, requestSendVoucherByEmail } from "./api";
export enum TransbankTransactionStatusEnum {
  Initilized = "INITIALIZED",
  Authorized = "AUTHORIZED",
  Reversed = "REVERSED",
  Failed = "FAILED",
  Nullified = "NULLIFIED",
  PartiallyNullified = "PARTIALLY_NULLIFIED",
  Captured = "CAPTURED",
}

/**
 * Resolver los re-renderizados que se producen en esta vista. Puede que tenga relación con el index de la raiz de paymentFlow
 * revisar el montaje de los componentes a partir del paso.
 */

//https://www.transbankdevelopers.cl/referencia/webpay#confirmar-una-transaccion
export interface PaymentResult {
  id: string;
  status: TransbankTransactionStatusEnum;
  integration: string;
  paymentTypeCode: string;
  amount: number;
  authorizationCode: string;
  installmentsNumber: number;
  installmentsAmount: number;
  transactionDate: string;
  vci: string;
  cardNumber: string;
  buyOrder: string;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const WebpayErrorDetails = () => {
  return (
    <>
      <p className="text-left">{t("webpay_error_rejected_details_desc")}</p>
      <ul className="text-left pl-3">
        <li>{t("webpay_error_rejected_details_1")}</li>
        <li>{t("webpay_error_rejected_details_2")}</li>
        <li>{t("webpay_error_rejected_details_3")}</li>
        <li>{t("webpay_error_rejected_details_4")}</li>
      </ul>
    </>
  );
};

type WebpayErrorType = "not found" | "unknown error" | "";

const VoucherStep = () => {
  const { isLogged } = useUserState();
  const history = useHistory();
  const resetStep = useStepStore((state) => state.resetStep);
  const setCurrentstep = useStepStore((state) => state.setCurrentStep);
  const query = useQuery();
  const webpayBuyOrder = query.get("webpayBuyOrder");
  const webpayError = query.get("webpayError");

  const [pdfUrl, setPdfUrl] = useState("");
  const [orderData, setOrderData] = useState<PaymentResult>();
  const [error, setError] = useState<WebpayErrorType>("");

  const openInNewTab = useCallback(() => {
    window.open(pdfUrl, "_blank", "noreferrer");
  }, [pdfUrl]);

  const handleReset = () => {
    if (isLogged) {
      history.replace("/user-dashboard/index");
      setCurrentstep(1);
    } else {
      history.replace("/dashboard/index");
      resetStep();
    }
  };

  const requestResult = useCallback(async () => {
    if (!webpayError && webpayBuyOrder) {
      try {
        setOrderData(undefined);
        setError("");
        const res = await requestGetPaymentResult(
          String(webpayBuyOrder),
          isLogged
        );
        setOrderData(res.data);
        const payerEmail = getAnonymousUserEmail();

        const { data } = await requestSendVoucherByEmail(
          webpayBuyOrder,
          isLogged,
          payerEmail
        );

        if (data) {
          setPdfUrl(data);
        }
      } catch (err: any) {
        if (err.response?.status === 404 || err.response?.status === 400) {
          setError("not found");
        } else {
          setError("unknown error");
        }
      }
    }
  }, [webpayError, webpayBuyOrder, isLogged]);

  useEffect(() => {
    requestResult();
  }, [requestResult]);

  if (error === "not found") {
    // Buy order doesn't exist
    return (
      <>
        <WebpayError
          title={t("payment_error_not_found")}
          instructions={t("payment_error_not_found_message")}
        />
        <div className="mx-auto container-max-400">
          <Row className="pb-3">
            <Col xs={12} className="">
              <CustomButton
                type="button"
                text={t("go_back_to_first_step")}
                onClick={handleReset}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }

  const webpayErrorDetails = webpayError ? <WebpayErrorDetails /> : null;

  return (
    <div>
      {!webpayError && webpayBuyOrder ? (
        <>
          <WebpaySuccessInfo
            orderData={orderData}
            request={requestResult}
            error={error}
            isLoading={!orderData && !error}
          />
          <div className="mx-auto container-max-400">
            <Row className="pb-4">
              <Col xs={12}>
                <div className="mt-4">
                  <Button
                    icon="download"
                    text={t("button_download_voucher")}
                    loading={!pdfUrl}
                    onClick={openInNewTab}
                    fullwidth
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="mx-auto container-max-400">
            <Row className="pb-3">
              <Col xs={12}>
                <CustomButton
                  type="button"
                  text={t("go_back_to_first_step")}
                  colorType="secondary"
                  onClick={handleReset}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : null}

      {webpayError ? (
        <>
          <WebpayError
            title={t("payment_error")}
            instructions={t("payment_error_custom_message", {
              message: decodeURIComponent(atob(query.get("webpayError") ?? "")),
            })}
            details={webpayErrorDetails}
          />
          <div className="mx-auto container-max-400 pt-3">
            <Row className="pb-3">
              <Col xs={12} className="">
                <CustomButton
                  type="button"
                  text={t("retry_payment")}
                  onClick={handleReset}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default memo(VoucherStep);
