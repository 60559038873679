import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import CustomerInfo from "../../../components/paySteps/CustomerInfo";
import WebpayPostRedirect from "../../../components/paySteps/WebpayPostRedirect";
import { useUserState } from "../../../hooks";
import { t } from "../../../utils/utils";

import useUserStore from "../store/user/userStore";
import DataTable from "./parts/Table";

import { Button } from "@octano/global-ui";
import { format } from "rut.js";
import useStepStore from "../store/step/stepStore";
import AnonymousUserInputs from "./parts/AnonymousUserInputs";
import GatewayModal, {
  GatewayModalMethods,
  GatewayOpenOptions,
} from "./parts/GatewayModal";
import { PayerRut } from "./types";

const PaymentStep = () => {
  const { user, debts } = useUserStore((state) => ({
    user: state.user,
    debts: state.debts,
  }));
  const prevStep = useStepStore((state) => state.prevStep);
  const { isLogged } = useUserState();
  const gateWayRef = useRef<GatewayModalMethods>(null);

  const [isPayLoading] = useState<boolean>(false);
  const [webpayRedirect] = useState<{
    token: string;
    initURL: string;
  }>();

  const handleOpenGateway = useCallback((opt: GatewayOpenOptions) => {
    gateWayRef?.current?.open(opt);
  }, []);

  const { control, formState, watch } = useForm<PayerRut>({
    defaultValues: {
      payerRut: user?.identifier,
      payerEmail: "",
    },
    mode: "all",
  });

  const { payerRut, payerEmail } = watch();

  //si esta logeado solo deben haber dedudas seleccionadas y si no, el formulario debe estar valido
  const disablePayment =
    !debts.some((d) => d.total && d.total > 0) ||
    (!isLogged
      ? !formState.isValid || !payerEmail.length || !payerRut.length
      : false);

  return (
    <>
      <CustomerInfo rut={format(user?.identifier)} name={user?.name} />
      <p className="text-left h5 mx-5">{t("payment_step_description")}</p>
      <DataTable />
      <AnonymousUserInputs isLogged={isLogged} control={control} />
      <div className="container-max-400 ml-auto my-3 mr-5">
        <Row>
          <Col xs={6} className="pr-1 pr-sm-2">
            <Button
              type="button"
              text={t("cancel")}
              onClick={() => prevStep()}
              outlined
              fullwidth
            />
          </Col>
          <Col xs={6} className="pl-1 pl-sm-2">
            <Button
              type="button"
              text={t("pay")}
              disabled={disablePayment}
              onClick={() =>
                handleOpenGateway({
                  isLogged,
                  payerRut,
                  payerEmail,
                })
              }
              loading={isPayLoading}
              fullwidth
            />
          </Col>
          {webpayRedirect ? <WebpayPostRedirect {...webpayRedirect} /> : null}
        </Row>
      </div>

      <GatewayModal ref={gateWayRef} onRequestSaveState={() => null} />
    </>
  );
};

export default PaymentStep;
