import { GridColDef } from "@mui/x-data-grid";
import { Icon } from "@octano/global-ui";
import dayjs from "dayjs";
import { format } from "rut.js";
import currencyFormat from "../../../../utils/currency-format";
import { CollectionTypeEquivalent } from "../../type";

export default function useColumns(): GridColDef[] {
  return [
    {
      field: "transactionDate",
      headerName: "Fecha de pago",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      valueFormatter: (params: string) => {
        return dayjs(params).format("DD/MM/YYYY");
      },
    },
    {
      field: "rut",
      headerName: "RUT Sustentador o Deudor",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return <p>{format(params.row.detail.debtOwner?.identifier)}</p>;
      },
    },
    {
      field: "reference",
      headerName: "Nº Dcto",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "custom",
      headerName: "Detalle",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <p>
            {params.row.program} - {params.row.detail.student.identifier}
          </p>
        );
      },
    },
    {
      field: "type",
      headerName: "Tipo",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params: string) => {
        return CollectionTypeEquivalent[params];
      },
    },
    {
      field: "installmentNumber",
      headerName: "Nº Cuota",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "installmentDueDate",
      headerName: "Fecha de vencimiento",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 150,
      valueFormatter: (params: string) => {
        return dayjs(params).format("DD/MM/YYYY");
      },
    },
    // {
    //   field: "total",
    //   headerName: "Código de Autorización",
    //   type: "number",
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    {
      field: "amount",
      headerName: "Monto pago",
      type: "custom",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return (
          <p>
            <strong>{currencyFormat(params.row.amount)}</strong>
          </p>
        );
      },
    },
    {
      field: "voucherUrl",
      headerName: "Ver comprobante",
      type: "custom",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return (
          <span onClick={() => window.open(params.value)}>
            <Icon name="file_pdf" color="primary" />
          </span>
        );
      },
    },
  ];
}
