import { StateStorage } from "zustand/middleware";
import {
  delFromIndexedDB,
  getFromIndexedDB,
  saveToIndexedDB,
} from "../../../utils/encript-storage";
import { get, set, del } from "idb-keyval";

export const SecureStorageStrategy: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await getFromIndexedDB(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await saveToIndexedDB(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await delFromIndexedDB(name);
  },
};

export const UnsafeStorageStrategy: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};
