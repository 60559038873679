// Hooks
import { Ref, forwardRef, useCallback, useImperativeHandle } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Utils
import { addToast } from "@octano/global-ui";

// Types
import { GatewayOpenOptions } from "..";

export type OneclickMethods = {
  handle: (options: GatewayOpenOptions) => void;
};

export type OneclickRetreived = {
  url: string;
};

type OneclickProps = {
  onSetLoading?: (next: boolean) => void;
  onTokenRetreived?: (response: OneclickRetreived) => void;
};

// Render
const Oneclick = (
  { onSetLoading, onTokenRetreived }: OneclickProps,
  ref: Ref<OneclickMethods>
) => {
  const { t } = useTranslation();

  // const handleToken = useCallback(
  //   (response: OneclickRetreived) =>
  //     !!onTokenRetreived && onTokenRetreived(response),
  //   [onTokenRetreived]
  // );

  const setLoading = useCallback(
    (next: boolean) => !!onSetLoading && onSetLoading(next),
    [onSetLoading]
  );

  const handlePayment = useCallback(
    async (options?: GatewayOpenOptions) => {
      try {
        setLoading(true);

        // const rut = !options?.isLogged
        //   ? clean(options?.payerRut || "").slice(0, -1)
        //   : undefined;

        // const response = await requestPayDebts([
        //   !!options?.isLogged,
        //   {
        //     //debts: payloadDebts,
        //     debts: [],
        //     rut,
        //     method: "oneclick",
        //   },
        // ])?.unwrap();
        // handleToken({
        //   url: response?.initURL,
        // });
      } catch (_error: any) {
        setLoading(false);
        addToast({
          icon: "error",
          color: "danger",
          text: _error?.data?.message?.trim() || t("unexpected_error_msg"),
        });
      }
    },
    [setLoading, t]
  );

  useImperativeHandle(ref, () => ({
    handle: handlePayment,
  }));

  return <></>;
};

export default forwardRef(Oneclick);
