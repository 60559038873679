export type TransalationKeys = {
  [key: string]: string;
};

export abstract class BaseException<T extends string> extends Error {
  protected translationKeys: TransalationKeys | undefined;

  constructor(
    message?: string | string[] | null | BaseException<T>,
    defaulErrorMessage?: T
  ) {
    if (message instanceof BaseException) {
      super(message.message);
      this.name = BaseException.name;
      return;
    }

    if (!message || (Array.isArray(message) && !message?.length)) {
      message = undefined;
    }
    if (typeof message === "string" && message?.trim()) {
      message = [message?.trim()];
    }

    super(message ? message[0] : defaulErrorMessage);
  }

  abstract defaultTranslationKeys(): TransalationKeys;

  public getTranslationKeys(): TransalationKeys {
    return this.translationKeys ?? this.defaultTranslationKeys();
  }

  public setTranslationKeys(translationKeys: TransalationKeys) {
    this.translationKeys = translationKeys;
  }

  public getExceptionCode(): T {
    return this.message as T;
  }

  public getTranslationKey(): string {
    const code = this.getExceptionCode();
    const keys = this.getTranslationKeys();
    return keys[code] ?? code;
  }
}
