import axios, { HttpStatusCode } from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000",
  withCredentials: true,
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === HttpStatusCode.Unauthorized &&
      window.location.pathname.includes("user-dashboard")
    ) {
      const sessionExpiredEvent = new CustomEvent("user-session-expired");
      window.dispatchEvent(sessionExpiredEvent);
    }
    return error;
  }
);

export default API;
